@import "app";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	font-family: "Quicksand", system-ui, sans-serif !important;
}