.artists-swiper-wrapper{
    .swiper-slide{
        width: initial !important;
    }
}

.atrist-images{
    .swiper-slide {
        width: 100% !important;
    } 
}

.search-page-artworks{
   .swiper{
    width: 100%;
   } 
}