.language-dropdown {
    display: flex;
    flex-flow: column;
    gap: 20px;
    background: #4A413D;
    padding: 5px;
    border-radius: 3px;
    position: absolute;
    left: -10px;
    top: 20px;

    ul {
        display: flex;
        gap: 15px;
        flex-flow: column;
        padding: 5px;
    }
}

.mobile-menu-sub-categories-block {
    max-height: 250px;
    overflow: auto;

}

.custom-scrollbar {
    --sb-track-color: #2c2a28;
    --sb-thumb-color: #968873;
    --sb-size: 4px;

    &::-webkit-scrollbar {
        width: var(--sb-size)
    }

    &::-webkit-scrollbar-track {
        background: var(--sb-track-color);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--sb-thumb-color);
        border-radius: 10px;
    }

    @supports not selector(::-webkit-scrollbar) {
        body {
            scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
        }
    }
}