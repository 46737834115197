// Width utilities
.w-100 {
    width: 100% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-auto {
    width: auto !important;
  }
  
  // Padding utilities
  .p-0 {
    padding: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .p-3 {
    padding: 1rem !important;
  }
  .p-4 {
    padding: 1.5rem !important;
  }
  .p-5 {
    padding: 3rem !important;
  }
  
  // Margin utilities
  .m-0 {
    margin: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .m-3 {
    margin: 1rem !important;
  }
  .m-4 {
    margin: 1.5rem !important;
  }
  .m-5 {
    margin: 3rem !important;
  }

  // Margin bottom utilities
.mb-0 {
    margin-bottom: 0 !important;
  }
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-5 {
    margin-bottom: 3rem !important;
  }
  
  // Padding bottom utilities
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-3 {
    padding-bottom: 1rem !important;
  }
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-5 {
    padding-bottom: 3rem !important;
  }
  
  // Padding top utilities
  .pt-0 {
    padding-top: 0 !important;
  }
  .pt-1 {
    padding-top: 0.25rem !important;
  }
  .pt-2 {
    padding-top: 0.5rem !important;
  }
  .pt-3 {
    padding-top: 1rem !important;
  }
  .pt-4 {
    padding-top: 1.5rem !important;
  }
  .pt-5 {
    padding-top: 3rem !important;
  }
  
  // Display utilities
  .d-block {
    display: block !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .d-inline {
    display: inline !important;
  }
  .d-flex {
    display: flex !important;
  }
  .d-inline-flex {
    display: inline-flex !important;
  }
  .d-none {
    display: none !important;
  }
  
  // Text alignment utilities
  .text-left {
    text-align: left !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-right {
    text-align: right !important;
  }
  
  // Background color utilities
  .bg-primary {
    background-color: #007bff !important;
  }
  .bg-secondary {
    background-color: #6c757d !important;
  }
  .bg-success {
    background-color: #28a745 !important;
  }
  .bg-danger {
    background-color: #dc3545 !important;
  }
  .bg-warning {
    background-color: #ffc107 !important;
  }
  .bg-info {
    background-color: #17a2b8 !important;
  }
  .bg-light {
    background-color: #f8f9fa !important;
  }
  .bg-dark {
    background-color: #343a40 !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .bg-transparent {
    background-color: transparent !important;
  }
  
  // Border utilities
  .border {
    border: 1px solid #dee2e6 !important;
  }
  .border-0 {
    border: 0 !important;
  }
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }
  
  // Border radius utilities
  .rounded {
    border-radius: 0.25rem !important;
  }
  .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  